export const lazyLoadImages = () => {
  const images = document.querySelectorAll("img[data-src]");

  const options = {
    rootMargin: "0px",
    threshold: 0.1,
  };

  const loadImage = (image) => {
    const img = new Image();
    img.src = image.getAttribute("data-src");
    img.onload = () => {
      image.setAttribute("src", img.src);
      image.removeAttribute("data-src");
      image.style.opacity = 1; // Set opacity to 1 when image is fully loaded
      image.style.filter = "none"; // Remove blur effect
    };

    img.onerror = () => {
      // Handle error if image fails to load
      // You can set a fallback image or handle it in a way that suits your needs
    };
  };

  const imageObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        loadImage(entry.target);
        observer.unobserve(entry.target);
      }
    });
  }, options);

  images.forEach((image) => {
    image.style.opacity = 0; // Set initial opacity to 0
    image.style.filter = "blur(8px)"; // Apply blur effect
    imageObserver.observe(image);
  });
};

export const shuffle = (array) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle
  while (0 !== currentIndex) {
    // Pick a remaining element
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};
