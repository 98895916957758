// import reginnnna_1 from "./Reginnna/1.jpg";
// import reginnnna_2 from "./Reginnna/2.jpg";
// import reginnnna_3 from "./Reginnna/3.jpg";
// import amanda2 from "./Amanda/2.jpg";
// import butterflybae from "./Butterfly Bae/1.jpg";
// import butterflybae2 from "./Butterfly Bae/2.jpg";
// import candy from "./Candy/1.jpg";
// import channel from "./Channel/1.jpg";
// import channel2 from "./Channel/2.jpg";
// import kaystania from "./Kaystania1/1.jpg";
// import kelly from "./Kelly/1.jpg";
// import kelly2 from "./Kelly/2.jpg";
// import risky from "./Risky/1.jpg";
// import risky2 from "./Risky/2.jpg";
// import risky3 from "./Risky/3.jpg";
// import royaltouch from "./Royal-Touch-Studios/1.jpg";
// import shaina from "./Shaina/1.jpg";
// import shaina2 from "./Shaina/2.jpg";
// import shaina3 from "./Shaina/3.jpg";
// import shaina4 from "./Shaina/4.jpg";
// import summer from "./Summer/1.jpg";
// import summer2 from "./Summer/2.jpg";
// import thandekile from "./Thandekile/1.jpg";
// import laqueen from "./Laqueen/1.jpg";
// import lina from "./Lina/1.jpg";
// import nisha from "./Nisha/1.jpg";
// import pinky from "./Pinky/1.jpg";
// import aisha from "./Aisha/1.jpg";
// import aisha2 from "./Aisha/2.jpg";
// import aisha3 from "./Aisha/3.jpg";
// import aisha4 from "./Aisha/4.jpg";
// import aisha5 from "./Aisha/5.jpg";
// import aisha6 from "./Aisha/6.jpg";
// import theuraputic from "./Theuraputic/1.jpg";
// import theuraputic1 from "./Theuraputic/2.jpg";
// import theuraputic2 from "./Theuraputic/3.jpg";
// import theuraputic3 from "./Theuraputic/4.jpg";
// import theuraputic4 from "./Theuraputic/5.jpg";
// import theuraputic5 from "./Theuraputic/6.jpg";
// import theuraputic6 from "./Theuraputic/7.jpg";
// import theuraputic7 from "./Theuraputic/8.jpg";
// import theuraputic8 from "./Theuraputic/9.jpg";
// import theuraputic9 from "./Theuraputic/10.jpg";
// import theuraputic10 from "./Theuraputic/11.jpg";
// import theuraputic11 from "./Theuraputic/12.jpg";
// import theuraputic12 from "./Theuraputic/13.jpg";
// import theuraputic13 from "./Theuraputic/14.jpg";
// import theuraputic14 from "./Theuraputic/15.jpg";
// import theuraputic15 from "./Theuraputic/16.jpg";
// import theuraputic16 from "./Theuraputic/17.jpg";
// import theuraputic17 from "./Theuraputic/18.jpg";

// export const PROFILES = [
//   {
//     id: 1,
//     whatsApp: "263776254497",
//     photo: aisha,
//     location: "Harare Town CBD Lizzy hotel ",
//     city: "Harare",
//     username: "Aisha  Babie🍑💦🥰",
//   },
//   {
//     id: 2,
//     whatsApp: "263776254497",
//     photo: aisha2,
//     location: "Harare Town CBD Lizzy hotel ",
//     city: "Harare",
//     username: "Aisha  Babie🍑💦🥰",
//   },
//   {
//     id: 3,
//     whatsApp: "263776254497",
//     photo: aisha3,
//     location: "Harare Town CBD Lizzy hotel ",
//     city: "Harare",
//     username: "Aisha  Babie🍑💦🥰",
//   },
//   {
//     id: 4,
//     whatsApp: "263776254497",
//     photo: aisha4,
//     location: "Harare Town CBD Lizzy hotel ",
//     city: "Harare",
//     username: "Aisha  Babie🍑💦🥰",
//   },
//   {
//     id: 5,
//     whatsApp: "263776254497",
//     photo: aisha5,
//     location: "Harare Town CBD Lizzy hotel ",
//     city: "Harare",
//     username: "Aisha  Babie🍑💦🥰",
//   },
//   {
//     id: 6,
//     whatsApp: "263776254497",
//     photo: aisha6,
//     location: "Harare Town CBD Lizzy hotel ",
//     city: "Harare",
//     username: "Aisha  Babie🍑💦🥰",
//   },

//   {
//     id: 7,
//     whatsApp: "263715557553",
//     photo: theuraputic,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 8,
//     whatsApp: "263715557553",
//     photo: theuraputic2,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 9,
//     whatsApp: "263715557553",
//     photo: theuraputic3,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 10,
//     whatsApp: "263715557553",
//     photo: theuraputic4,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 11,
//     whatsApp: "263715557553",
//     photo: theuraputic5,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 12,
//     whatsApp: "263715557553",
//     photo: theuraputic6,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 13,
//     whatsApp: "263715557553",
//     photo: theuraputic7,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 14,
//     whatsApp: "263715557553",
//     photo: theuraputic8,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 15,
//     whatsApp: "263715557553",
//     photo: theuraputic9,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 16,
//     whatsApp: "263715557553",
//     photo: theuraputic10,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 17,
//     whatsApp: "263715557553",
//     photo: theuraputic11,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 18,
//     whatsApp: "263715557553",
//     photo: theuraputic12,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },

//   {
//     id: 19,
//     whatsApp: "263715557553",
//     photo: theuraputic13,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 20,
//     whatsApp: "263715557553",
//     photo: theuraputic14,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 21,
//     whatsApp: "263715557553",
//     photo: theuraputic15,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 22,
//     whatsApp: "263715557553",
//     photo: theuraputic16,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 23,
//     whatsApp: "263715557553",
//     photo: theuraputic17,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },
//   {
//     id: 24,
//     whatsApp: "263715557553",
//     photo: theuraputic1,
//     location: "Gunhill number 14169 Griffin Road",
//     city: "Harare",
//     username: "Paradise Theuraputic Massage Spa",
//   },

//   {
//     id: 25,
//     whatsApp: "263713417282",
//     photo: kelly,
//     location: "Travel Only",
//     city: "Harare",
//     username: "Kelly",
//   },
//   {
//     id: 25,
//     whatsApp: "263713417282",
//     photo: kelly2,
//     location: "Travel Only",
//     city: "Harare",
//     username: "Kelly",
//   },
//   {
//     id: 26,
//     whatsApp: "263736013052",
//     photo: amanda,
//     city: "Harare",
//     location: "Harare",
//     username: "Amanda",
//   },
//   {
//     id: 27,
//     whatsApp: "263736013052",
//     photo: amanda2,
//     city: "Harare",
//     location: "Harare",
//     username: "Amanda",
//   },
//   {
//     id: 28,
//     whatsApp: "263776150032",
//     photo: butterflybae,
//     city: "Harare",
//     location: "Harare",
//     username: "Butterfly Bae",
//   },
//   {
//     id: 29,
//     whatsApp: "263776150032",
//     photo: butterflybae2,
//     city: "Harare",
//     location: "Harare",
//     username: "Butterfly Bae",
//   },
//   {
//     id: 30,
//     whatsApp: "263785162317",
//     photo: candy,
//     city: "Harare",
//     location: "Harare",
//     username: "Candy",
//   },
//   {
//     id: 31,
//     whatsApp: "263783996960",
//     photo: channel,
//     city: "Harare",
//     location: "Harare",
//     username: "Channel",
//   },

//   {
//     id: 32,
//     whatsApp: "263783996960",
//     photo: channel2,
//     city: "Harare",
//     location: "Harare",
//     username: "Channel",
//   },
//   {
//     id: 33,
//     whatsApp: "263788339117",
//     photo: kaystania,
//     city: "Harare",
//     location: "Harare",
//     username: "Kaystania",
//   },
//   {
//     id: 34,
//     whatsApp: "263774396927",
//     photo: laqueen,
//     city: "Harare",
//     location: "Harare",
//     username: "Laqueen",
//   },
//   {
//     id: 35,
//     whatsApp: "263719372427",
//     photo: lina,
//     city: "Harare",
//     location: "Harare",
//     username: "Lina",
//   },
//   {
//     id: 36,
//     whatsApp: "263780395750",
//     photo: nisha,
//     city: "Harare",
//     location: "Harare",
//     username: "Nisha",
//   },
//   {
//     id: 37,
//     whatsApp: "263781291631",
//     photo: pinky,
//     city: "Harare",
//     location: "Harare",
//     username: "Pinky",
//   },

//   {
//     id: 38,
//     whatsApp: "263719638349",
//     photo: risky,
//     city: "Harare",
//     location: "Harare",
//     username: "Risky",
//   },
//   {
//     id: 39,
//     whatsApp: "263719638349",
//     photo: risky2,
//     city: "Harare",
//     location: "Harare",
//     username: "Risky",
//   },
//   {
//     id: 40,
//     whatsApp: "263719638349",
//     photo: risky3,
//     city: "Harare",
//     location: "Harare",
//     username: "Risky",
//   },

//   {
//     id: 41,
//     whatsApp: "263788281557",
//     photo: royaltouch,
//     city: "Harare",
//     location: "Harare",
//     username: "Royal Touch",
//   },
//   {
//     id: 42,
//     whatsApp: "263772111764",
//     photo: shaina,
//     city: "Harare",
//     location: "Harare",
//     username: "Shaina",
//   },
//   {
//     id: 43,
//     whatsApp: "263772111764",
//     photo: shaina2,
//     city: "Harare",
//     location: "Harare",
//     username: "Shaina",
//   },
//   {
//     id: 44,
//     whatsApp: "263772111764",
//     photo: shaina3,
//     city: "Harare",
//     location: "Harare",
//     username: "Shaina",
//   },
//   {
//     id: 45,
//     whatsApp: "263772111764",
//     photo: shaina4,
//     city: "Harare",
//     location: "Harare",
//     username: "Shaina",
//   },
//   {
//     id: 46,
//     whatsApp: "263781157149",
//     photo: summer,
//     city: "Harare",
//     location: "Harare",
//     username: "Summer",
//   },
//   {
//     id: 47,
//     whatsApp: "263781157149",
//     photo: summer2,
//     city: "Harare",
//     location: "Harare",
//     username: "Summer",
//   },
//   {
//     id: 48,
//     whatsApp: "263786956341",
//     photo: thandekile,
//     city: "Harare",
//     location: "Harare",
//     username: "Thandekile",
//   },
// ];

export const PROFILES = [
  // {
  //   id: 1,
  //   whatsApp: "27636270464",
  //   photo: reginnnna_1,
  //   location: "Cape Town City Centre, Cape Town, 8000",
  //   city: "Cape Town",
  //   username: "Reginnnna🍑💦🥰",
  // },
  // {
  //   id: 2,
  //   whatsApp: "27636270464",
  //   photo: reginnnna_2,
  //   location: "Cape Town City Centre, Cape Town, 8000",
  //   city: "Cape Town",
  //   username: "Reginnnna🍑💦🥰",
  // },
  // {
  //   id: 3,
  //   whatsApp: "27636270464",
  //   photo: reginnnna_3,
  //   location: "Cape Town City Centre, Cape Town, 8000",
  //   city: "Cape Town",
  //   username: "Reginnnna🍑💦🥰",
  // },
];
