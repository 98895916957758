import React from "react";
import "../css/Nav.css";

const Nav = () => {
  return (
    <nav>
      <div>
        <h1>hangouts</h1>
      </div>



      <div>
      <h1>Spreading God's Love</h1>
        {/* <button
          className="btn btn-primary"
          onClick={() => {
            // const email = "realrachelstorms@gmail.com";
            // const subject = "New Account Request";
            // const body = "Hi, I need a hangouts account";
            // const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
            //   subject
            // )}&body=${encodeURIComponent(body)}`;
            // window.location.href = mailtoUrl;
            const message =
              "Hi, I would like to advertise my Massage Spa on hangouts";
            const url = `https://wa.me/263716500556?text=${encodeURIComponent(
              message
            )}`;
            window.open(url, "_blank");
          }}
        >
          Advertise Your Massage Business
        </button> */}
      </div>
    </nav>
  );
};

export default Nav;
