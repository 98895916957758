import React from "react";
import "../css/Cards.css";
import { PROFILES } from "../../../profiles/api";
import { shuffle } from "../../../helpers/_functions";
import Card from "./Card";
import EmptyComponent from "./EmptyComponent"; // Import the component to render when PROFILES.length is 0

const Cards = () => {
  const shuffledProfiles = shuffle(PROFILES);
  return (
    <div className="cards">
      {PROFILES.length === 0 ? (
        <EmptyComponent /> // Render the EmptyComponent when PROFILES.length is 0
      ) : (
        shuffledProfiles.map((profile) => (
          <Card
            image={profile.photo}
            whatsApp={profile.whatsApp}
            username={profile.username}
            location={profile.location}
          />
        ))
      )}
    </div>
  );
};

export default Cards;
