import React, { useEffect } from "react";
import "../css/Card.css";
import { lazyLoadImages } from "../../../helpers/_functions";

const Card = ({ image, whatsApp, username, location }) => {
  useEffect(() => {
    lazyLoadImages();
  }, []);

  return (
    <div className="card">
      <div className="card-name">
        <h3>🔥 {username}</h3>
        <small>📍 {location}</small>
      </div>
      <div className="card-img">
        <img data-src={image} alt="hangouts" />
      </div>
      <div
        className="card-whatsApp"
        onClick={() => {
          const phoneNumber = whatsApp;

          const message =
            "Hi, let's Hangout, I am interested in your escort services. What are your prices";
          const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
            message
          )}`;
          window.open(url, "_blank");
        }}
      >
        <i className="fa-brands fa-whatsapp"></i>
        <h4>Start Chatting</h4>
      </div>
    </div>
  );
};

export default Card;
