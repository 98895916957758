import React from "react";
import "./css/Home.css";
import Cards from "./components/Cards";

const Index = () => {
  return (
    <div className="container home">
      <Cards />
    </div>
  );
};

export default Index;
