import React from "react";
import "../css/EmptyComponent.css";

const EmptyComponent = () => {
  // const [siteVisits, setSiteVisits] = useState(5000);

  // useEffect(() => {
  //   const randomStartValue = Math.floor(Math.random() * 1000) + 5000; // Generate a random value between 5000 and 6000
  //   setSiteVisits(randomStartValue);

  //   const interval = setInterval(() => {
  //     const increment = Math.floor(Math.random() * 3) + 1; // Randomly choose increment between 1, 4, or 5
  //     setSiteVisits((prevVisits) => prevVisits + increment);
  //   }, 60000); // Run every minute (60000 milliseconds)

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="empty">
<div>
  <h1>Acts 3:19</h1>
  <p>
    Repent, then, and turn to God, so that your sins may be wiped out, that times of refreshing may come from the Lord.
  </p>
</div>

      {/* <div className="site-visits-container">🚀
        <h2>
          Current active site visits{" "}
          <span className="site-visits"> {siteVisits}</span>{" "}
          <i className="fa-solid fa-arrow-trend-up"></i>
        </h2>
      </div> */}

      <div>
        <button
          className="btn btn-primary"
          onClick={() => {
            const url = `https://www.youversion.com/the-bible-app/`;
            window.open(url, "_blank");
          }}
        >
          YouVersion Bible App
        </button>
      </div>
    </div>
  );
};

export default EmptyComponent;
